import React, { useState, useEffect } from "react";
import { useStitchAuth } from "../App/StitchAuth";
import BillingInfo from '../SignUp'
import { BSON } from 'mongodb-stitch-browser-sdk';
import { clients } from "../MongoStitch";

export default function ClubSignup(props) {
  const [queryString, setQueryString] = useState(window.location.search);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(queryString));
  const [uid, setUid] = useState(urlParams.get('uid'));
  //const [uid, setUid] = useState('6262e2c45e4782dc6068e3d6')
  const [clubData, setClubData] = useState({ club: {} });
  const [activeStatus, setActiveStatus] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const {
    actions
  } = useStitchAuth();
  const [inputText, setInputText] = useState({});
  useEffect(() => {
    if (uid !== null) {
      activeStatusCall()
    }
  }, [uid]);

  useEffect(() => {
    if (activeStatus === true) {
      findTheClub()
    }
  }, [activeStatus]);

  const activeStatusCall = async () => {
    const provider = 'anonymous';
    actions.handleLogin({
      provider
    }).then(function (result) {
      const agg = [{
        $match: {
          "clubs._id": new BSON.ObjectId(uid)
        }
      }, {
        $project: {
          _id: 0,
          active: 1
        }
      }]
      clients.aggregate(agg).asArray()
        .then(data => {
          setActiveStatus(data[0].active)
        })
        .catch(err => console.error(`Failed to find and update document: ${err}`))
    })
      .catch(err => alert(`${err}`))
  }

  const findTheClub = async () => {
    const provider = 'anonymous';
    actions.handleLogin({
      provider
    }).then(function (result) {
      const agg = [{
        $match: {
          "clubs._id": new BSON.ObjectId(uid)
        }
      }, {
        $project: {
          _id: 0,
          'activeStates': {
            '$filter': {
              'input': '$stateCompliance',
              'as': 'state',
              'cond': {
                '$eq': [
                  '$$state.offSite', true
                ]
              }
            }
          },
          'club': {
            '$filter': {
              'input': '$clubs',
              'as': 'club',
              'cond': {
                '$eq': [
                  '$$club._id', new BSON.ObjectId(uid)
                ]
              }
            }
          },
          producerName: 1,
          stateCompliance: 1,
          formSignupUsers: 1,
          logo: "$defaultLocation.logo_url"
        }
      }, {
        $unwind: {
          path: '$club',
        }
      }]
      clients.aggregate(agg).asArray()
        .then(data => {
          var formSignupUsers = []
          if (data[0].formSignupUsers) {
            if (data[0].formSignupUsers[0]) {
              formSignupUsers = data[0].formSignupUsers
            }
          }
          setClubData({
            ...data[0],
            "formSignupUsers": formSignupUsers
          })
        })
        .catch(err => console.error(`Failed to find and update document: ${err}`))
        .catch(err => alert(`${err}`))
    })
      .catch(err => alert(`${err}`))
  }

  return (
    <>
      <div className="container-fluid" style={{ "height": "100vh" }}>
        <div className="row justify-content-center">
          <div className="col-12 justify-self-center" align="center">
            <img width="100px" src={clubData.logo} style={{ "paddingTop": "28px" }}></img>
            <h1>{clubData.producerName}</h1>
            <h2>{clubData.club.name}</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-md-8 justify-self-center" align="center">
            <p>{clubData.club.description}</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-md-7">
            {
              activeStatus === true ?
                <BillingInfo activeStatus={activeStatus} notification_email={clubData.club.notification_email} club_name={clubData.club.name} mailchimpList={clubData.club.mailchimpList} uid={uid} pickup={clubData.club.pickup} ship={clubData.club.shipping} states={clubData.activeStates} allStates={clubData.stateCompliance} prodName={clubData.producerName} teamMembers={clubData.club.signUpFormTeam} teamMemberList={clubData.formSignupUsers} />
                :
                null
            }
          </div>
        </div>
      </div>
    </>
  );
}