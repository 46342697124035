import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import { app } from "./app";

const mongoClient = app.getServiceClient(
  RemoteMongoClient.factory,
  "mongodb-atlas"
);


const clients = mongoClient.db("VinApp_v1-0-0-Beta").collection("clients");
export { clients };
