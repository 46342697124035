import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { app } from "../MongoStitch/app";

export default function PaymentPage(props) {
  function handleChange() {
    // Here, we invoke the callback with the new value
    props.onChange(true);
  }
  return (
    <>
      <PaymentForm
        applicationId="sq0idp-9RKkgfA6tYoTrjr17WLgQQ"
        createVerificationDetails={() => ({
          // You can avoid amount and currency if the intent is `STORE`
          // amount: '1.00',
          // currencyCode: 'USD',
          // `CHARGE` or `STORE`
          intent: 'STORE',
          billingContact: {
            addressLines: ['123 Main Street', 'Apartment 1'],
            familyName: 'Doe',
            givenName: 'John',
            email: 'jondoe@gmail.com',
            country: 'GB',
            phone: '3214563987',
            region: 'LND',
            city: 'London',
          },
        })}
        cardTokenizeResponseReceived={(token, verifiedBuyer) => {
          app.callFunction("SQUARE_Customers", ['createCustomerCard', { onlineSignup: true, clubId: props.uid, customerId: props.customerId, card_nonce: token.token, postal_code: token.postalCode, verification_token: verifiedBuyer.token }]).then(result => {
            switch (result.status) {
              case 400:
                alert('There was a problem adding your card.  Please check the card details and try again.');
                break;
              case 410:
                alert('ERROR: ' + result.text);//Vincipia error based on mongo return
                break;
              case 200: {
                handleChange()
              }
                break;
              default:
                alert('Uh Oh! Something seems to have gone wrong.  Please try again.')
            }
          })
            .catch(err => alert('There was an error adding your card.  Please check the card details and try again.'))
        }}
        locationId="YCPZ66A61R6WD"

      >
        <CreditCard
          callbacks={{
          }}
          render={(Button) => <Button>Submit</Button>}
        />
      </PaymentForm>
    </>
  );
}