import React, {useState,useEffect} from "react";
import './App.css';
import {StitchAuthProvider,useStitchAuth} from "./StitchAuth";
import ClubSignup from '../ClubSignup';

export default function App() {
 
  useEffect(() => {
   
  }, []);

    return (
      <StitchAuthProvider>
      <ClubSignup />
      </StitchAuthProvider>
    )
}